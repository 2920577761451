import React, { useEffect, useState } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import ThemeConfig from "./theme_config_dark.json";

type Props = {
  children: React.ReactNode;
};

const Theme = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState<DefaultTheme | null>(null);

  useEffect(() => {
    setIsLoading(true);
    try {
      const stringifiedThemeConfig = JSON.stringify(ThemeConfig);
      const themeObject = JSON.parse(stringifiedThemeConfig) as DefaultTheme;
      setTheme(themeObject);
    } catch (error) {
      throw new Error("Theme not loaded correctly");
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    //TODO: Add loading view
    return null;
  }

  if (!theme) {
    //TODO: Add error view
    return null;
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export { Theme };
